import React from 'react'

import { PageLayout, PageBreadcrumb } from '../../components'
import { t } from '../../lib/locale'

const ContentStringBYOTemplate = ({ pageContext }) => {
  const { lang, page } = pageContext

  const items = []

  items.push({
    path_en: '/en',
    path_nl: '/nl',
    name_en: 'Home',
    name_nl: 'Home'
  })

  items.push({
    path_en: '/en/brands',
    path_nl: '/nl/merken',
    name_en: 'Brands',
    name_nl: 'Merken'
  })

  items.push({
    path_en: '/en/brands/34-String',
    path_nl: '/nl/merken/34-String',
    name_en: 'String Furniture',
    name_nl: 'String Furniture'
  })

  return (
    <PageLayout className="shopping-bag" lang={lang} switcher={page}>
      <PageBreadcrumb current={page} items={items} lang={lang} />
      <h1>{t(page, 'title', lang)}</h1>

      <iframe
        src="https://build-your-own.stringfurniture.com?lan=en&countrycode=BE&guid=sit_OTE1Mzk1OTMwMjk"
        width="960px"
        height="730px" />

    </PageLayout>
  )
}

export default ContentStringBYOTemplate
